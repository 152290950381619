import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import {
  Divider,
  DialogTitle,
  DialogActions,
  DialogContent,
  Modal as DefaultModal,
  ModalClose,
  ModalDialog,
  Sheet,
  Typography,
  ModalProps as DefaultModalProps,
} from '@mui/joy';
import { SxProps } from '@mui/material';
import React from 'react';

export type ModalProps = Omit<DefaultModalProps, 'children'> & {
  /**
   * A string to display at the top of the modal.
   */
  title?: string;

  /**
   * A string to display within the modal, with a description of the purpose of the modal.
   */
  description?: string;

  /**
   * The type of modal window to display.
   *
   * A Dialog is a type of modal window that appears in front of app content to provide critical information or ask for a decision.
   * Dialogs disable all app functionality when they appear, and remain on screen until confirmed, dismissed, or a required action has been taken.
   */
  variant?: 'default' | 'dialog' | 'dialog-warning';

  /**
   * The content to display inside the modal.
   */
  children?: JSX.Element | JSX.Element[];

  /**
   * Actions to display at the bottom of the modal.
   */
  actions?: JSX.Element | JSX.Element[];

  /**
   * Whether to hide the close button.
   */
  hideCloseButton?: boolean;
};

export function Modal(props: ModalProps): JSX.Element {
  const { title, description, variant = 'default', sx: $sxOverride, children, ...rest } = props;

  const sx = $sxOverride ?? {
    display: { xs: 'initial', md: 'flex' },
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'scroll',
  };

  return (
    <DefaultModal
      {...(title ? { 'aria-labelledby': 'modal-title' } : {})}
      {...(description ? { 'aria-describedby': 'modal-description' } : {})}
      sx={sx}
      {...rest}
      /**
       * Solves the issue where Select components in the modal are not working properly.
       */
      disableAutoFocus
      disableEnforceFocus>
      {variant === 'dialog' || variant === 'dialog-warning' ? (
        <ModalWithDialog {...props}>{children}</ModalWithDialog>
      ) : (
        <ModalWithoutDialog {...props}>{children}</ModalWithoutDialog>
      )}
    </DefaultModal>
  );
}

function ModalTitle(props: ModalProps): JSX.Element {
  if (!props.title) {
    return <></>;
  }

  if (['dialog', 'dialog-warning'].includes(props.variant)) {
    return (
      <DialogTitle id="modal-title" level="h4">
        {props.variant === 'dialog-warning' && <WarningRoundedIcon fontSize={'inherit'} />}{' '}
        {props.title}
      </DialogTitle>
    );
  }

  return (
    <>
      <Typography
        id="modal-title"
        level="h4"
        textColor="inherit"
        mb="0.25em"
        startDecorator={
          props.variant === 'dialog-warning' ? <WarningRoundedIcon fontSize={'inherit'} /> : null
        }>
        {props.title}
      </Typography>
    </>
  );
}

function ModalDescription(props: ModalProps): JSX.Element {
  if (!props.description) {
    return <></>;
  }

  if (['dialog', 'dialog-warning'].includes(props.variant)) {
    return <DialogContent id="modal-description">{props.description}</DialogContent>;
  }

  return (
    <Typography id="modal-description" level="body-lg" textColor="text.tertiary" mb={3}>
      {props.description}
    </Typography>
  );
}

function ModalActions(props: ModalProps): JSX.Element {
  if (!props.actions) {
    return <></>;
  }

  if (['dialog', 'dialog-warning'].includes(props.variant)) {
    return <DialogActions>{props.actions}</DialogActions>;
  }

  return <>{props.actions}</>;
}

const BaseSx: SxProps = {
  boxShadow:
    '0px 0.6875rem 0.9375rem -0.4375rem rgba(0, 0, 0, 0.20), 0px 1.5rem 2.375rem 0.1875rem rgba(0, 0, 0, 0.14), 0px 0.5625rem 2.875rem 0.5rem rgba(0, 0, 0, 0.12)',
  minWidth: { xs: 'calc(100% - 32px)', md: '400px', lg: '680px' },
  maxWidth: { xs: 'auto', lg: 'min(900px, calc(100% - 250px))' },
  overflow: 'scroll',
  maxHeight: { xs: '100%', sm: '80%' },
  justifyContent: 'space-between',
  gap: '1.5rem',
  borderRadius: '8px',
};

const ModalWithDialog = React.forwardRef<HTMLDivElement, ModalProps>(function ModalWithDialog(
  props: ModalProps,
  ref
) {
  return (
    <ModalDialog
      variant="outlined"
      {...(props.variant === 'dialog-warning' ? { role: 'alertdialog' } : {})}
      sx={BaseSx}
      ref={ref}>
      {props.hideCloseButton ? null : <ModalClose size="lg" />}
      <ModalTitle {...props} />
      {props.variant === 'dialog-warning' && <Divider />}
      <ModalDescription {...props} />
      {props.children}
      <ModalActions {...props} />
    </ModalDialog>
  );
});

const ModalWithoutDialog = React.forwardRef<HTMLDivElement, ModalProps>(function ModalWithoutDialog(
  props: ModalProps,
  ref
) {
  return (
    <Sheet
      variant="outlined"
      ref={ref}
      sx={{
        ...BaseSx,
        p: 3,
        position: 'relative',
        zIndex: '10',
      }}>
      {props.hideCloseButton ? null : <ModalClose size="lg" />}
      <ModalTitle {...props} />
      <ModalDescription {...props} />
      {props.children}
      <ModalActions {...props} />
    </Sheet>
  );
});
